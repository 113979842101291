/* eslint-disable no-restricted-globals */
import axios from 'axios';
import { omit } from 'lodash-es';

const getDiagostics = () => ({
  windowWidth: window.outerWidth,
  windowHeight: window.outerHeight,
  screenOrientation: screen.orientation.type,
  userAgent: navigator.userAgent,
  urlOrigin: window.location.origin,
  urlPathname: window.location.pathname,
});

const getUser = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  return omit(user, ['dateOfBirth', 'gender']);
};

export const logEvent = async (eventName, details) => {
  try {
    const payload = {
      eventName,
      user: getUser(),
      diagnostics: getDiagostics(),
      ...details,
    };
    await axios.post('/api/logInfo', payload);
    console.log('logInfo', payload);
  } catch (err) {
    console.error('logInfo', err);
  }
};

export const logError = async (eventName, error) => {
  try {
    const payload = {
      eventName,
      user: getUser(),
      diagnostics: getDiagostics(),
      error: error.message && error.stack
        ? { message: error.message, stack: error.stack }
        : String(error),
    };
    await axios.post('/api/logError', payload);
    console.log('logError', payload);
  } catch (err) {
    console.error('logError', err);
  }
};
