/* eslint-disable jsx-a11y/anchor-is-valid */
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';
import { camelCase } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import validator from 'validator';
import XLSX from 'xlsx-js-style';

import IndividualListExample from '../../../../assets/itpmetrics/individual-survey-participant-list-example.png';
import TeamListExample from '../../../../assets/itpmetrics/team-survey-participant-list-example.png';
import { addRespondents, getRandomNames } from '../../../../bridge';
import { isIndividualTemplateType } from '../../../../helpers/itpmetricsUtils';
import { logError } from '../../../../helpers/logger';

const renameMappings = {
  group: 'team',
  givenName: 'firstName',
};

function UploadParticipantListModal({
  surveyId, templateType, onCancel, onChanged,
}) {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [list, setList] = useState([]);
  const [missingFieldsMessage, setMissingFieldsMessage] = useState(null);
  const [error, setError] = useState(null);

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setMissingFieldsMessage(null);
    setError(null);

    try {
      const arrayBuffer = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (e) => reject(e);
        reader.readAsArrayBuffer(file);
      });

      const data = new Uint8Array(arrayBuffer);

      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      const camelCasedData = jsonData.map((obj) => {
        const entry = {};
        Object.keys(obj).forEach((key) => {
          const camelKey = camelCase(key);
          if (renameMappings[camelKey]) {
            entry[renameMappings[camelKey]] = obj[key];
          } else {
            entry[camelKey] = obj[key];
          }
        });
        return entry;
      });

      setList(camelCasedData);
    } catch (e) {
      logError('upload_participant_list_modal_on_file_change', e);
    }
  };

  const onClickUpload = async () => {
    try {
      if (!selectedFile || !selectedFile.name) {
        setSelectedFile(null);
        return;
      }

      const requiredFields = ['firstName', 'lastName', 'email', 'team'];
      if (isIndividualTemplateType(templateType)) {
        requiredFields.splice(requiredFields.length - 1, 1);
      }
      const missingFields = requiredFields.filter(
        (field) => !list[0] || !Object.keys(list[0]).includes(field),
      );

      if (missingFields.length > 0) {
        const missingFieldsFormatted = missingFields.map((field) => lodash.startCase(field)).join(', ');
        setMissingFieldsMessage(`Please include the following fields in your list: ${missingFieldsFormatted}`);
        return;
      }

      const invalidEmails = list.filter(({ email }) => !validator.isEmail(email))
        .map(({ email }) => email);
      if (invalidEmails.length > 0) {
        setError(`Invalid email(s): ${invalidEmails.join(' ')}`);
        return;
      }

      const missingCells = list.filter((entry) => requiredFields.find((field) => !entry[field] || entry[field].trim().length === 0));
      if (missingCells.length > 0) {
        const cells = Object.values(missingCells[0]).map((cell) => cell.trim());
        setError(`Missing data for "${lodash.compact(cells).join(', ')}"`);
        return;
      }

      const cleanedList = list.map(({
        firstName, lastName, email, team,
      }) => ({
        firstName: String(firstName).trim(),
        lastName: String(lastName).trim(),
        email: String(email).trim().toLowerCase(),
        team: team ? String(team).trim() : null,
      }));

      const { error: err } = await addRespondents(surveyId, cleanedList);
      if (err) {
        setError(`${err.message}: ${err.emails.join(', ')}`);
        return;
      }
      onChanged();
    } catch (err) {
      logError('upload_participant_list_modal_on_click_upload', err);
    }
  };

  const onClickChooseFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onClickCancel = () => {
    setSelectedFile({});
    setList([]);
    onCancel();
  };

  const onClickUseDemoList = async () => {
    const { response: names } = await getRandomNames(20);
    await addRespondents(surveyId, names);
    onChanged();
  };

  return (
    <Modal show centered onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>Upload Participant List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please upload an Excel or CSV file and ensure that your list aligns with the example
          demonstrated in the image below.
        </p>
        <p>
          {'Just trying out the survey? '}
          <a
            style={{ cursor: 'pointer', textDecorationLine: 'underline' }}
            onClick={onClickUseDemoList}
          >
            Click here to use a demo list
          </a>
        </p>
        {isIndividualTemplateType(templateType)
          ? <Image src={IndividualListExample} className="img-fluid w-50" />
          : <Image src={TeamListExample} className="img-fluid w-50" />}
        <br />
        <br />
        <Button onClick={onClickChooseFile} variant="outline-primary">
          <FontAwesomeIcon icon={faUpload} />
          {' Select File'}
        </Button>
        <br />
        <input
          type="file"
          id="fileInput"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept=".xls, .xlsx, .csv"
          onChange={onFileChange}
        />
        {selectedFile && (
        <small>{selectedFile.name}</small>
        )}

        {!selectedFile && <div className="text-danger fw-bold">Please upload a file!</div>}
        {missingFieldsMessage && <div className="text-danger fw-bold">{missingFieldsMessage}</div>}
        {error && <div className="text-danger fw-bold">{error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClickCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onClickUpload}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

UploadParticipantListModal.propTypes = {
  surveyId: PropTypes.string,
  templateType: PropTypes.string,
  onCancel: PropTypes.func,
  onChanged: PropTypes.func,
};

export default UploadParticipantListModal;
