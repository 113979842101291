import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { logIn } from '../../../bridge';
import AppContext from '../../../context/Context';
import { logEvent } from '../../../helpers/logger';

function LoginModal({ show, onHide }) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { config: { rememberedEmail = '' }, setConfig } = useContext(AppContext);
  const [email, setEmail] = useState(params.get('email') ?? rememberedEmail);

  const defaultMessage = 'We will send you a one-time access link to your email.';
  const [message, setMessage] = useState(defaultMessage);
  const [hideForm, setHideForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (!show) {
      setMessage(defaultMessage);
      setHideForm(false);
    }
  }, [show]);

  const onComplete = () => {
    setMessage(`Please check your email inbox for ${email}. Use the link in the email to access your account.`);
    setHideForm(true);
  };

  const handleSubmit = async () => {
    const lowercased = email.toLowerCase();
    setConfig('rememberedEmail', lowercased);
    setIsLoading(true);
    await logIn(lowercased);
    onComplete(lowercased);
    setIsLoading(false);
    logEvent('sign_in', { user: { email: lowercased } });
  };

  return (
    <Modal
      show={show}
      centered
      onHide={() => onHide()}
    >
      <Modal.Header>
        <Modal.Title>Log in</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{message}</p>
        {!hideForm && (
        <Form.Group className="mb-3">
          <Form.Control
            placeholder=""
            value={email}
            name="email"
            onChange={handleFieldChange}
            type="email"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSubmit();
              }
            }}
            autoFocus
          />
        </Form.Group>
        )}
      </Modal.Body>
      {!hideForm && (
        <Modal.Footer>
          <Button color="primary" disabled={!email || isLoading} onClick={handleSubmit}>
            {isLoading ? 'Logging in...' : 'Log in'}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

LoginModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

LoginModal.defaultProps = {
  show: false,
  onHide: () => {},
};

export default LoginModal;
