import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { logIn, verifyCode } from '../../../bridge';
import { logError, logEvent } from '../../../helpers/logger';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLogin } from '../../../hooks/useLogin';

function LockScreen({ show }) {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { handleDispatch } = useLogin();
  const { search, pathname } = useLocation();
  const { user } = useAuthContext();
  const params = new URLSearchParams(search);
  const email = params.get('email') ?? user?.email;
  const code = params.get('code');
  const muted = params.get('muted');
  const respondentId = params.get('respondentId');

  useEffect(() => {
    if (!email || !code) {
      return;
    }
    const verify = async () => {
      const emailLowerCased = email.toLowerCase();
      const { error: err, response } = await verifyCode(emailLowerCased, code);
      if (err) {
        logError('lock_screen_verify', err);
        if (!muted) {
          await logIn(emailLowerCased, respondentId);
        }
        setError(err);
      } else {
        logEvent('lock_screen_verify', { user: { email: emailLowerCased } });
        handleDispatch(response.token);
      }
    };
    verify();
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (respondentId) {
      localStorage.setItem('respondentId', respondentId);
    }
    if (!user.policyId) {
      navigate('/profile');
      return;
    }
    if (respondentId || pathname === '/verify') {
      if (user.email === email) {
        navigate('/dashboard');
      }
    }
  }, [user]);

  return (
    <Modal show={show} centered>
      <Modal.Body className="p-0">
        <Card>
          <Card.Body className="fs--1 fw-normal p-4 text-center">
            {error ? (
              <>
                <h5>{error}</h5>
                {!muted && (
                  <>
                    <strong>We have sent you an updated access code.</strong>
                    <div>{`Please check your ${email} inbox for another email.`}</div>
                  </>
                )}
              </>
            ) : <h5>Verifying access code...</h5>}
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}

LockScreen.propTypes = {
  show: PropTypes.bool,
};

LockScreen.defaultProps = {
  show: false,
};

export default LockScreen;
