/* eslint-disable react/no-array-index-key */
import { RadarChart as eRadarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { sortBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { getLegacyFeedbackReview } from '../../../bridge';
import { formatShortDate } from '../../../helpers/itpmetricsUtils';
import { logError } from '../../../helpers/logger';
import ContentSection from '../common/ContentSection';
import Loading from '../common/Loading';
import Page from '../common/Page';
import ReportError from './ReportError';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  eRadarChart,
  CanvasRenderer,
  LegendComponent,
  MarkLineComponent,
]);

function FeedbackReview() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { key } = useParams();
  const [surveys, setSurveys] = useState(null);
  const [options, setOptions] = useState({});
  const [qualitativeOptions, setQualitativeOptions] = useState([]);

  useEffect(() => {
    if (!key) {
      return;
    }
    const fetchFeedbackReview = async () => {
      try {
        const { response } = await getLegacyFeedbackReview(key);
        setSurveys(sortBy(response, ['assessmentDateCreated']).map((survey) => ({
          name: survey.assessmentName,
          date: survey.assessmentDateCreated,
          quantitative: !survey.report ? {} : survey.report.find((r) => r.name === 'Peer Feedback'),
          qualitative: !survey.report ? {} : survey.report.find((r) => r.name === 'Peer Feedback Comments'),
        })).reverse());
        setLoading(false);
      } catch (err) {
        setError(err);
        logError('feedback_review_fetch', err);
      }
    };
    fetchFeedbackReview();
  }, [key]);

  useEffect(() => {
    if (!surveys) {
      return;
    }
    const buckets = {
      Commitment: 'spot1',
      Communication: 'spot2',
      Skills: 'spot3',
      Standards: 'spot4',
      Focus: 'spot5',
    };
    const series = Object.keys(buckets).map((name) => {
      const spot = buckets[name];
      const data = surveys.map((survey) => {
        const score = Object.values(survey.quantitative.scores || {})
          .find(({ category }) => category === spot);
        if (!score) {
          return false;
        }
        return Number(Number(score.score).toFixed(1));
      });
      return {
        name,
        type: 'bar',
        barGap: 0,
        label: {
          show: true,
          position: 'left',
          distance: -20,
          align: 'left',
          verticalAlign: 'middle',
          formatter: '{c} {a}',
          fontSize: 16,
          textBorderWidth: 0,
          rich: {
            name: {},

          },
        },
        emphasis: {
          focus: 'series',
        },
        data,
      };
    });

    const opt = {
      xAxis: [
        {
          min: 1,
          max: 5,
          type: 'value',
        },
      ],
      yAxis: [
        {
          align: 'right',
          type: 'category',
          axisTick: { show: false },
          data: surveys.map(({ name, date }) => `${name} \n (${formatShortDate(date)})`),
          axisLabel: {
            margin: 20,
            width: '90',
            overflow: 'break',
          },
        },
      ],
      grid: { containLabel: true, left: 0 },
      series,
    };
    setOptions(opt);
  }, [surveys]);

  useEffect(() => {
    if (!surveys) {
      return;
    }

    setQualitativeOptions(surveys.map((survey) => {
      const self = survey && survey.qualitative && survey.qualitative.self ? survey.qualitative.self : [];
      return ({
        name: survey.name,
        given: Object.values(self[0] || {}),
        received: (survey.qualitative || {}).scores,
      });
    }));
  }, [surveys]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ReportError />;
  }

  return (
    <Page>
      <ContentSection>
        <h3>Feedback Review</h3>
        <ReactEChartsCore
          echarts={echarts}
          option={options}
          style={{ height: `${surveys.length * 250}px`, width: '1000px' }}
        />

        {qualitativeOptions.map((option, index) => (
          <Row key={`qualitative-row-${index}`} className="pb-5">
            <h4>{option.name}</h4>
            <Col key={`qualitative-option-given-col-${index}`} md="6">
              <div style={{ fontWeight: 'bold' }}>Feedback given</div>
              <br />
              {option.given.map((text, textIndex) => (
                <blockquote key={`qualitative-option-feedback-given-col-${index}-${textIndex}`} style={{ fontStyle: 'italic' }}>
                  {text}
                </blockquote>
              ))}
            </Col>
            <Col key={`qualitative-option-received-col-${index}`} md="6">
              <div style={{ fontWeight: 'bold' }}>Feedback received</div>
              <br />
              {(option.received || []).map((text, textIndex) => (
                <blockquote key={`qualitative-option-feedback-received-col-${index}-${textIndex}`} style={{ fontStyle: 'italic' }}>
                  {text}
                </blockquote>
              ))}
            </Col>
          </Row>
        ))}
        <br />
        <footer className="justify-content-center">
          &copy; Copyright 2023 itpmetrics.com
        </footer>
      </ContentSection>
    </Page>
  );
}

export default FeedbackReview;
