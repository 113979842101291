import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { downloadRawData } from '../../../../bridge';
import { logError, logEvent } from '../../../../helpers/logger';

function DownloadRawData({ survey }) {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    logEvent('download_raw_data', { surveyId: survey.surveyId });
    setDownloading(true);
    try {
      const { response } = await downloadRawData(survey.surveyId);
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${survey.name}_raw_data.xlsx`;
      link.click();
      link.remove();
      setDownloading(false);
    } catch (error) {
      logError('download_raw_data', error);
      setDownloading(false);
    }
  };

  return (
    <Button
      title="Download raw data"
      size="md"
      variant="light"
      onClick={handleDownload}
      disabled={downloading}
    >
      <FontAwesomeIcon icon={faDownload} />
    </Button>
  );
}

DownloadRawData.propTypes = {
  survey: PropTypes.shape({
    surveyId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    templateId: PropTypes.string,
    createdBy: PropTypes.string,
    status: PropTypes.string,
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

export default DownloadRawData;
