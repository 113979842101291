import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';
import { camelCase } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import XLSX from 'xlsx-js-style';

import IndividualListExample from '../../../../assets/itpmetrics/individual-survey-participant-list-example.png';
import { addReviewParticipants } from '../../../../bridge';
import { logError } from '../../../../helpers/logger';

const renameMappings = {
  givenName: 'firstName',
};

function UploadReviewParticipantListModal({
  reviewId, onCancel, onChanged,
}) {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [list, setList] = useState([]);
  const [missingFieldsMessage, setMissingFieldsMessage] = useState(null);

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setMissingFieldsMessage(null);

    try {
      const arrayBuffer = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });

      const data = new Uint8Array(arrayBuffer);

      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      const camelCasedData = jsonData.map((obj) => {
        const entry = {};
        Object.keys(obj).forEach((key) => {
          const camelKey = camelCase(key);
          if (renameMappings[camelKey]) {
            entry[renameMappings[camelKey]] = obj[key];
          } else {
            entry[camelKey] = obj[key];
          }
        });
        return entry;
      });

      setList(camelCasedData);
    } catch (error) {
      logError('upload_review_rarticipant_list_modal_on_file_change', error);
    }
  };

  const onClickUpload = async () => {
    try {
      if (!selectedFile || !selectedFile.name) {
        setSelectedFile(null);
        return;
      }

      const requiredFields = ['firstName', 'lastName', 'email'];
      const missingFields = requiredFields.filter(
        (field) => !list[0] || !Object.keys(list[0]).includes(field),
      );

      if (missingFields.length > 0) {
        const missingFieldsFormatted = missingFields.map((field) => lodash.startCase(field)).join(', ');
        setMissingFieldsMessage(`Please include the following fields in your list: ${missingFieldsFormatted}`);
        return;
      }

      await addReviewParticipants(reviewId, list);
      onChanged();
    } catch (error) {
      logError('upload_review_rarticipant_list_modal_on_click_upload', error);
    }
  };

  const onClickChooseFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onClickCancel = () => {
    setSelectedFile({});
    setList([]);
    onCancel();
  };

  return (
    <Modal show centered onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>Upload Participant List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please upload an Excel or CSV file and ensure that your list aligns with the example
          demonstrated in the image below.
        </p>
        <Image src={IndividualListExample} className="img-fluid w-50" />
        <br />
        <br />
        <Button onClick={onClickChooseFile} variant="outline-primary">
          <FontAwesomeIcon icon={faUpload} />
          {' Select File'}
        </Button>
        <br />
        <input
          type="file"
          id="fileInput"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept=".xls, .xlsx, .csv"
          onChange={onFileChange}
        />
        {selectedFile && (
        <small>{selectedFile.name}</small>
        )}

        {!selectedFile && <small className="text-danger fw-bold">Please upload a file!</small>}
        {missingFieldsMessage && <small className="text-danger fw-bold">{missingFieldsMessage}</small>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClickCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onClickUpload}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

UploadReviewParticipantListModal.propTypes = {
  reviewId: PropTypes.string,
  onCancel: PropTypes.func,
  onChanged: PropTypes.func,
};

export default UploadReviewParticipantListModal;
