import React, { useEffect, useState } from 'react';
import { Alert, Container, ProgressBar } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { getTemplate } from '../../../bridge';
import { findProgress } from '../../../helpers/itpmetricsUtils';
import { logError } from '../../../helpers/logger';
import Loading from '../common/Loading';
import NotFound from '../common/NotFound';
import Page from '../common/Page';
import SurveyBody from './components/SurveyBody';

function Template() {
  const [template, setTemplate] = useState(null);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const { templateId, pageIndex: pageIndexStr } = useParams();
  const pageIndex = Number(pageIndexStr);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const { error, response } = await getTemplate(templateId);
      setLoading(false);
      if (error) {
        logError('template_use_effect', error);
        return;
      }
      await setTemplate(response.template);
      await setTotal(response.count);
    };
    fetch();
  }, []);
  if (loading) {
    return <Loading />;
  }
  if (!template) {
    return <NotFound subject="template" />;
  }
  return (
    <Page>
      <Container fixed="true" style={{ minWidth: '300px' }}>
        <h1 className="py-2">
          {`${template.name} Survey Preview`}
        </h1>
        <Alert variant="danger">
          Preview Mode: Responses will not be submitted
        </Alert>
        <ProgressBar now={findProgress(template, pageIndex, total)} />
        <SurveyBody template={template} total={total} pageIndex={pageIndex} isPreview />
      </Container>
    </Page>
  );
}

export default Template;
